// import image
import coreLogo from "../../image/Logo_core.svg";
import bannerImg from "../../image/glowing-circuit-board.jpg";
import sectionImg from "../../image/digital-art-ai-technology.jpg";
import micImg from "../../image/core/aiassistant2.jpg";
// import data
import contentData from "../../data/content/core/coreContent.json";
import { DataStructure, DataKeys } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, SpacingFrame } from "../display/Frame";
import { BusinessBannerSection } from "../display/Sections";
import { SingleProductCard } from "../display/Cards";
import { ImageImport, ImageBanner, ImageContain } from "../display/ImageFormat";
import { TextBody, TextBulletList, TitleSubtitle, TextParagraphHeader } from "../display/TextFormat";
import { TechBanner } from "./About/About";
import { ArdiToolsOverview } from "../content/ToolsOverview";
// import functions
import { filterAISolutionsData } from "../../functions/DataFunctions";
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";

const typedContent : DataStructure = contentData;
const imagePath = "image/core/";

export default function Core() {
    const { lang } = useLang();
    const data = typedContent["Core"];
    const section1 = data[1];
    const filterSolutions = filterAISolutionsData(section1["solutions"]) as DataKeys;
    const solutionsData = Object.values(filterSolutions)[0];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ coreLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    {/* <SingleProductCard data={ solutionsData } imagePath={imagePath} /> */}

                    <Col2ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <ArdiToolsOverview isScroll />

            <ImageBanner image={sectionImg} />
        </>
    )
};

export function CoreAiAssistant() {
    const { lang } = useLang();
    const data = typedContent["ai-assistant"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];

    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={128}>
                    <Col2ContentFrame>
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section1["title"][lang] } left />
                            <TextBody text={ section1["text"][lang] } />
                            <TextBulletList list={ section1["list"][lang] } />
                        </SpacingFrame>
                        <ImageImport image={ section1["image"] } imagePath={imagePath} />
                    </Col2ContentFrame>

                    <Col2ContentFrame>
                        <ImageContain image={micImg} />
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section2["title"][lang] } left />
                            <TextBody text={ section2["text"][lang] } />
                            <TextBulletList list={ section2["list"][lang] } />
                        </SpacingFrame>
                    </Col2ContentFrame>

                    <Col2ContentFrame>
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section3["title"][lang] } left />
                            <TextBody text={ section3["text"][lang] } />
                        </SpacingFrame>
                        <ImageImport image={ section3["image"] } imagePath={imagePath} />
                    </Col2ContentFrame>

                    <Col2ContentFrame>
                        <ImageImport image={ section4["image"] } imagePath={imagePath} />
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section4["title"][lang] } left />
                            <TextParagraphHeader data={ section4["content"] } isBulletList />
                        </SpacingFrame>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>
        </>
    )
};