import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import image
import manufactureLogo from "../../image/Logo_manufacture.svg";
import bannerImg from "../../image/manufacturing/engineer-controlling-robotic-arm.jpg";
// import data
import contentData from "../../data/content/manufacturing/manufacturingContent.json";
import AiToolsData from "../../data/aiTools.json";
import { DataStructure } from "../../types";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { Col2ContentFrame, MainFrame, SpacingFrame, Col3ContentFrame } from "../display/Frame";
import { BannerSection, BusinessBannerSection, BusinessUnitSection } from "../display/Sections";
import { TitleSubtitle } from "../display/TextFormat";
import NoContent, { LoadingContent } from "../content/NoContent";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";
import { SlideInAnim } from "../tools/Animations";

const typedContent : DataStructure = contentData;
const typedAiTools : DataStructure = AiToolsData;
const imagePath = "image/manufacturing/";

interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function Manufacturing() {
    const { lang } = useLang();
    const data = typedContent["Manufacturing"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const content = data[4];
    const section5 = data[5];

    let manufacturing_key_factors = Object.keys(section3["content"]).map((i) => {
        let data = section3["content"][i];
        let image = require("../../image/icons/" + data["icon"]);
        return (
            <div className="w-full h-fit flex flex-col items-center justify-center gap-8">
                <div className="relative w-20 h-20 rounded-full bg-teal-500">
                    <div className="absolute w-20 h-20 flex items-center justify-center">
                        <img className="w-10 h-10" src={ image } />
                    </div>
                </div>
                <SpacingFrame spacing={12}>
                    <p className="text-md font-semibold">{ data["title"][lang] }</p>
                    <p className="text-sm text-grey-400">{ data["subtitle"][lang] }</p>
                </SpacingFrame>
            </div>
        )
    });

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ manufactureLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={40}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <div style={{ backgroundColor: "rgba(162, 218, 219, 0.4)"}}>
                <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} />
            </div>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)", overflow: "hidden" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section3["title"][lang] } subtitle={ section3["subtitle"][lang] } />
                    <SlideInAnim direction="bottom">
                        <Col3ContentFrame>
                           {manufacturing_key_factors}
                        </Col3ContentFrame>
                    </SlideInAnim>
                </SpacingFrame>
            </MainFrame>

            <BusinessUnitSection 
                title={ section5["title"][lang] } 
                text={ section5["text"][lang] } 
                bulletList={ section5["list"][lang] }
                data={ typedAiTools["agni"] }
            />

            <DisplayInfo2Cols contentData={content} imagePath={imagePath} /> 
        </>
    )
};


export function ManufacturingSubpages() {
    const { lang } = useLang();
    const { manufacturingSolutions } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<DataStructure | undefined>(undefined);
    const [contentData, setContentData] = useState<DataStructure | undefined>(undefined);

    useEffect(() => {
        if (manufacturingSolutions) {
            // Assume typedToolsContent is defined and imported correctly
            setData(typedContent[manufacturingSolutions] || undefined);
        };
    }, [manufacturingSolutions]);

    useEffect(() => {
        if (data) {
            setContentData(data["content"] || undefined);
            setLoading(false);
        } else {
            setLoading(false);
        };
    }, [data]);

    if (loading) {
        return <LoadingContent />
    };

    if (!data) {
        return <NoContent />
    };

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            { contentData && <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} /> }
        </>
    )
};
