// import images
import bannerImg from "../../../image/about/group-of-young-people.jpeg";
import groupImg from "../../../image/about/group_pic.jpeg";
import techBannerImg from "../../../image/glowing-circuit-board.jpg";
import techSecImg from "../../../image/about/technologies/glowing-blue-cyborg.jpg";
import drlinImg from "../../../image/people/cylin.jpg";
// import data
import contentData from "../../../data/content/about/aboutContent.json";
import aiToolsData from "../../../data/aiTools.json";
import { DataStructure, DataKeys } from "../../../types";
// import components
import { NavTree, ExploreOtherServices } from "../../navigation/NavLinkPages";
import { MainFrame, HorizontalScrollArrowsFrame, ColAutoFrame, SpacingFrame, Col2ContentFrame, Col3ContentFrame } from "../../display/Frame";
import { BannerSection, MainIdeaSection } from "../../display/Sections";
import { ProductCard, LeftBorderCard, ToolCard, MemberCard } from "../../display/Cards";
import { TitleSubtitle, TextBody, TextBulletList } from "../../display/TextFormat";
import { ImageBanner, ImageContain, ImageImport } from "../../display/ImageFormat";
import { MoreButton } from "../../tools/Buttons";
import { SlideInAnim } from "../../tools/Animations";
// import functions
import { filterPeopleData } from "../../../functions/DataFunctions";
// import context
import { useLang } from "../../Context";
import { DisplayInfo2Cols } from "../../../functions/DisplayInfoFunctions";

const typedContent : DataStructure = contentData;
const typedTools : DataStructure = aiToolsData;


interface BannerProps {
    title: string;
    subtitle: string;
};
export function AboutDefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};

export default function About() {
    const { lang } = useLang();
    const data = typedContent["About"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];
    const section7 = data[7];

    // ai tools:
    let toolsCard = Object.keys(typedTools).map((i) => {
        let data = typedTools[i];
        return (
            <ToolCard data={data} />
        )
    });

    // section 5: people
    const peopleData = filterPeopleData(section5["people"]) as DataStructure;
    let membershipCards = Object.keys(peopleData).map((i) => {
        let data = peopleData[i] as DataKeys;
        return (
            <MemberCard data={data} />
        )
    });

    return (
        <>
            <AboutDefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />

            <NavTree />

            <MainIdeaSection text={ section1["text"][lang] } />

            <DisplayInfo2Cols contentData={ section2["content"] } imagePath="image/" title={ section2["title"][lang] } subtitle={ section2["subtitle"][lang] } />

            <ImageBanner image={groupImg} />

            <MainFrame>
                <TitleSubtitle title={ section3["title"][lang] } spacing={30}  />
                <SpacingFrame>
                    <TextBody text={ section3["content"]["text"][lang] } />
                    <p className="text-lg text-left font-semibold">{ section3["content"]["subtitle"][lang] }</p>
                    <LeftBorderCard data={ section3["content"][0] } isBulletList />
                </SpacingFrame>

                <DisplayInfo2Cols contentData={section7["content"]} imagePath="" title={ section7["title"][lang] } />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#A2DADB" }}>
                <SpacingFrame spacing={44}>
                    <TitleSubtitle title={ section4["title"][lang] } subtitle={ section4["subtitle"][lang] } spacing={30} />
                    <HorizontalScrollArrowsFrame arrowStyle={{ color: "#A2DADB" }}>
                        { toolsCard }
                    </HorizontalScrollArrowsFrame>
                    <MoreButton label="Explore All Technologies" link="/about/technologies" darkMode />
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "#F5F5F5" }}>
                <SpacingFrame spacing={44}>
                    <TitleSubtitle 
                        title={ section5["title"][lang] }
                        subtitle={ section5["subtitle"][lang] }
                        spacing={32}
                    />
                    <HorizontalScrollArrowsFrame>
                        { membershipCards }
                    </HorizontalScrollArrowsFrame>
                    <MoreButton label="Meet Leadership" link="/about/team" />
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section6["title"][lang] } />
                    <Col2ContentFrame>
                        <div className="grid grid-flow-row auto-rows-min gap-6 text-base">
                            <TextBody text={ section6["content"]["text"][lang] } />
                            <p className="text-lg text-left font-semibold">{ section6["content"]["subtitle"][lang] }</p>
                            <TextBulletList list={ section6["content"]["list"][lang] } />
                        </div>
                        <SlideInAnim direction="right">
                            <ImageImport image={ section6["content"]["image"] } imagePath="image/about/" />
                        </SlideInAnim>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>
        </>
    )
};

interface TechBannerProps {
    title: string;
    subtitle: string;
}
TechBanner.defaultProps = {
    subtitle: "The Next-Generation Enterprise-Ready Full-Brain AI Platform"
};
export function TechBanner({ title, subtitle } : TechBannerProps) {
    return (
        <BannerSection image={techBannerImg} title={ title } subtitle={ subtitle } />
    )
};
export function Technologies() {
    const { lang } = useLang();
    const data = typedContent["Technologies"];
    const section1 = data[1];
    const section2 = data[2];

    // section 1: services
    let services = Object.keys(section1["content"]).map((i) => {
        let data = section1["content"][i];
        return (
            <ProductCard data={data} imagePath="image/about/technologies/"  />
        )
    });

    // section 2: advantages
    let advantages = Object.keys(section2["content"]).map((i) => {
        let content = section2["content"][i];
        return (
            <LeftBorderCard data={content} isBulletList />
        )
    });

    return (
        <>
            <TechBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={64}>
                    <TitleSubtitle title={ section1["title"][lang] } subtitle={ section1["subtitle"][lang] } />
                    <Col3ContentFrame>
                        { services }
                    </Col3ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <ImageBanner image={techSecImg} />

            <MainFrame>
                <SpacingFrame spacing={64}>
                    <TitleSubtitle title={ section2["title"][lang] } />
                    <ColAutoFrame>
                        { advantages }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
        </>
    )
};


export function TeamMembers() {
    const { lang } = useLang();
    const data = typedContent["Team"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];

    function leadershipCard(data: DataKeys) {
        const imageSrc = require("../../../image/people/" + data["image"] );

        return (
            <div className="h-fit max-h-min lg:grid lg:grid-cols-3 sm:flex sm:flex-col gap-8">
                <div className="col-span-1 mx-auto">
                    <img className="h-[270px] w-[300px] object-cover bg-cover bg-center" src={imageSrc} />
                </div>
                <div className="col-span-2 flex flex-col gap-2 my-auto">
                    <p className="text-lg text-left font-semibold">{ data["name"][lang] }</p>
                    <div className="flex flex-col gap-1">
                        <p className="text-sm text-left text-grey-400 font-semibold">{ data["title"][lang] }</p>
                        <p className="text-sm text-left text-grey-400">{ data["summary"][lang] }</p>
                    </div>
                </div>
            </div>
        )
    };

    // section 1: founder
    let founderContent = Object.keys(section1["content"]).map((i) => {
        let textData = section1["content"][i];
        return (
            <div className="flex flex-col gap-4 p-2">
                <p className="text-lg text-left font-semibold">{ textData["subtitle"][lang] }</p>
                <p className="text-sm text-left text-grey-400">{ textData["text"][lang] }</p>
            </div>
        )
    });

    // section 2: graphen leadership
    const graphenPeople = filterPeopleData(section2["people"]) as DataStructure;
    let graphenLeadership = Object.keys(graphenPeople).map((i) => {
        let data = graphenPeople[i] as DataKeys;
        return (
            leadershipCard(data)
        )
    });

    // section 3: drugomics leadership
    const drugomicsPeople = filterPeopleData(section3["people"]) as DataStructure;
    let drugomicsLeadership = Object.keys(drugomicsPeople).map((i) => {
        let data = drugomicsPeople[i] as DataKeys;
        return (
            leadershipCard(data)
        )
    });

    return (
        <>            
            <AboutDefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />

            <NavTree />

            <MainFrame>
                <SpacingFrame spacing={48}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <div className="h-fit max-h-min lg:grid lg:grid-cols-5 sm:flex sm:flex-col gap-8">
                        <div className="col-span-2">
                            <ImageContain image={drlinImg} />
                        </div>
                        <div className="col-span-3">
                            { founderContent }
                        </div>
                    </div>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame spacing={48}>
                    <TitleSubtitle 
                        title={ section2["title"][lang] } 
                        subtitle={ section2["subtitle"][lang] } 
                        spacing={48}
                    />
                    { graphenLeadership }
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle 
                        title={ section3["title"][lang] } 
                        subtitle={ section3["subtitle"][lang] } 
                        spacing={48}
                    />
                    { drugomicsLeadership }
                </SpacingFrame>
            </MainFrame>

            <ExploreOtherServices titleKey="Explore Medical Solutions" servicesList={["drugomics"]} />
        </>
    )
}