import { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import './App.css';
// import components
import HomePage, { AllSolutions } from './components/pages/Homepage';
import Robotics, { Aiia, StoreSolutions, Adam } from "./components/pages/Robotics";
import Agents, { RestaurantsAndCafés, ElectronicsAndAppliances, BuildingMaterialsAndHardwares, Furniture, Supermarket, HotelsAndConferences, Attractions, Transportation } from "./components/pages/Agents";

import Core, { CoreAiAssistant } from "./components/pages/Core";
import Finance, { FinanceSubpages, FinanceTraderAnita } from "./components/pages/Finance";
// import Energy, { NuclearFusion } from "./components/pages/Energy";
import Mobility, { MobilitySubpages } from "./components/pages/Mobility";
import Manufacturing, { ManufacturingSubpages } from "./components/pages/Manufacturing";
import Drugnomics, { DrugDesign, VaccineDesign, PersonalizedMedicine, DrugomicsPipeline, DrugPipelineContent, DrugPipelineContentFibrosis } from "./components/pages/Drugomics";
import Health, { HealthSubpages } from "./components/pages/Health";
import Security, { SecuritySubpages } from "./components/pages/Security";
import About, { Technologies, TeamMembers } from "./components/pages/About/About";
import Atom, { AtomTools } from "./components/pages/About/Atom";
import Ardi, { ArdiTools} from "./components/pages/About/Ardi";
import AboutTechSubpages from "./components/pages/About/AIPlatforms";
import Careers, { ContactUs, OpenPositions } from "./components/pages/Careers";
import Blog, { BlogSubpages } from "./components/pages/Blog";
import NewsRoom, { NewsSubpages } from "./components/pages/Newsroom";
import NoContent from "./components/content/NoContent";
import JoinUsSection from "./components/content/JoinUs";
import Nav from "./components/navigation/NavBar";
import Footer from "./components/navigation/Footer";
// import functions
import { scrollTop } from "./functions/DisplayFunctions";
// import context
import { useLang, useNavTab, useMobileNavOpen } from "./components/Context";

export default function App() {
    const { lang } = useLang();
    const { setNavTab } = useNavTab();
    const { setMobileNavOpen } = useMobileNavOpen();
    const location = useLocation();
    const [showJoinUs, setShowJoinUs] = useState<boolean>(false);

    // close navBar when navigating to new page
    useEffect(() => {
        setNavTab(d => ({...d, Name: "", Open: false}));
        scrollTop();
        setMobileNavOpen(false);

        if (location.pathname.includes("/contact-us")) {
            setShowJoinUs(false);
        } else {
            setShowJoinUs(true);
        };
    }, [location.pathname]);

    useEffect(() => {
        setNavTab(d => ({...d, Name: "", Open: false}));
        setMobileNavOpen(false);
    }, [lang]);

    return (
        <>
            <Nav />
            <Routes>
                <Route path={ lang === "Zh" ? "/tw" : lang === "Jp" ? "/jp" : "/" } element={ <HomePage /> } />
                <Route path={ lang === "Zh" ? "/tw/all-solutions" : lang === "Jp" ? "/jp/all-solutions" : "/all-solutions" }element={ <AllSolutions /> } />

                <Route path={ lang === "Zh" ? "/tw/robotics" : lang === "Jp" ? "/jp/robotics" : "/robotics" } element={ <Robotics /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/digital-human" : lang === "Jp" ? "/jp/robotics/digital-human" : "/robotics/digital-human" } element={ <Aiia /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/store-solutions" : lang === "Jp" ? "/jp/robotics/store-solutions" : "/robotics/store-solutions" } element={ <StoreSolutions /> } />
                <Route path={ lang === "Zh" ? "/tw/robotics/adam" : lang === "Jp" ? "/jp/robotics/adam" : "/robotics/adam" } element={ <Adam /> } />

                <Route path={ lang === "Zh" ? "/tw/agents" : lang === "Jp" ? "/jp/agents" : "/agents" } element={ <Agents /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/restaurants-and-cafes" : lang === "Jp" ? "/jp/agents/restaurants-and-cafes" : "/agents/restaurants-and-cafes" } element={ <RestaurantsAndCafés /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/hotels-and-conferences" : lang === "Jp" ? "/jp/agents/hotels-and-conferences" : "/agents/hotels-and-conferences" } element={ <HotelsAndConferences /> } />

                <Route path={ lang === "Zh" ? "/tw/agents/electronics-and-appliances" : lang === "Jp" ? "/jp/agents/electronics-and-appliances" : "/agents/electronics-and-appliances" } element={ <ElectronicsAndAppliances /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/building-materials-and-hardwares" : lang === "Jp" ? "/jp/agents/building-materials-and-hardwares" : "/agents/building-materials-and-hardwares" } element={ <BuildingMaterialsAndHardwares /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/furniture" : lang === "Jp" ? "/jp/agents/furniture" : "/agents/furniture" } element={ <Furniture /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/supermarket" : lang === "Jp" ? "/jp/agents/supermarket" : "/agents/supermarket" } element={ <Supermarket /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/attractions" : lang === "Jp" ? "/jp/agents/attractions" : "/agents/attractions" } element={ <Attractions /> } />
                <Route path={ lang === "Zh" ? "/tw/agents/transportation" : lang === "Jp" ? "/jp/agents/transportation" : "/agents/transportation" } element={ <Transportation /> } />


                <Route path={ lang === "Zh" ? "/tw/core" : lang === "Jp" ? "/jp/core" : "/core" } element={ <Core /> } />
                <Route path={ lang === "Zh" ? "/tw/core/ardi-platform" : lang === "Jp" ? "/jp/core/ardi-platform" : "/core/ardi-platform" } element={<Navigate to={ lang === "Zh" ? "/tw/about/technologies/ardi" : lang === "Jp" ? "/jp/about/technologies/ardi" : "/about/technologies/ardi" }replace />} />
                {/* <Route path={ lang === "Zh" ? "/tw/core/ai-assistant" : "/core/ai-assistant" } element={ <CoreAiAssistant /> } /> */}
                <Route path={ lang === "Zh" ? "/tw/core/aiia" : lang === "Jp" ? "/jp/core/aiia" : "/core/aiia" } element={<Navigate to={ lang === "Zh" ? "/tw/robotics/digital-human" : lang === "Jp" ? "/jp/robotics/digital-human" : "/robotics/digital-human" }replace />} />

                <Route path={ lang === "Zh" ? "/tw/finance" : lang === "Jp" ? "/jp/finance" : "/finance" } element={ <Finance /> } />
                <Route path={ lang === "Zh" ? "/tw/finance/:financeSolutions" : lang === "Jp" ? "/jp/finance/:financeSolutions" : "/finance/:financeSolutions" } element={ <FinanceSubpages /> } />
                <Route path={ lang === "Zh" ? "/tw/finance/personal-financial-advisor/alpha-ai-trader-anita" : lang === "Jp" ? "/jp/finance/personal-financial-advisor/alpha-ai-trader-anita" : "/finance/personal-financial-advisor/alpha-ai-trader-anita" } element={ <FinanceTraderAnita /> } />

                {/* <Route path="/energy" element={ <Energy /> } /> */}
                {/* <Route path="/energy/nuclear-fusion" element={ <NuclearFusion /> } /> */}

                <Route path={ lang === "Zh" ? "/tw/drugomics" : lang === "Jp" ? "/jp/drugomics" : "/drugomics" } element={ <Drugnomics /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design" : lang === "Jp" ? "/jp/drugomics/drug-design" : "/drugomics/drug-design" } element={ <DrugDesign /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design/pipeline" : lang === "Jp" ? "/jp/drugomics/drug-design/pipeline" : "/drugomics/drug-design/pipeline" } element={ <DrugomicsPipeline /> } />
                {/* <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design/pipeline/osteoarthritis" : lang === "Jp" ? "/jp/drugomics/drug-design/pipeline/osteoarthritis" : "/drugomics/drug-design/pipeline/osteoarthritis" } element={ <DrugPipelineContent /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/drug-design/pipeline/fibrotic-disorders" : lang === "Jp" ? "/jp/drugomics/drug-design/pipeline/fibrotic-disorders" : "/drugomics/drug-design/pipeline/fibrotic-disorders" } element={ <DrugPipelineContentFibrosis /> } /> */}

                <Route path={ lang === "Zh" ? "/tw/drugomics/vaccine-design" : lang === "Jp" ? "/jp/drugomics/vaccine-design" : "/drugomics/vaccine-design" } element={ <VaccineDesign /> } />
                <Route path={ lang === "Zh" ? "/tw/drugomics/personalized-medicine" : lang === "Jp" ? "/jp/drugomics/personalized-medicine" : "/drugomics/personalized-medicine" } element={ <PersonalizedMedicine /> } />

                <Route path={ lang === "Zh" ? "/tw/health" : lang === "Jp" ? "/jp/health" : "/health" } element={ <Health /> } />
                <Route path={ lang === "Zh" ? "/tw/health/:healthSolutions" : lang === "Jp" ? "/jp/health/:healthSolutions" : "/health/:healthSolutions" } element={ <HealthSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/mobility" : lang === "Jp" ? "/jp/mobility" : "/mobility" } element={ <Mobility /> } />
                <Route path={ lang === "Zh" ? "/tw/mobility/:mobilitySolutions" : lang === "Jp" ? "/jp/mobility/:mobilitySolutions" : "/mobility/:mobilitySolutions" } element={ <MobilitySubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/manufacturing" : lang === "Jp" ? "/jp/manufacturing" : "/manufacturing" } element={ <Manufacturing /> } />
                <Route path={ lang === "Zh" ? "/tw/manufacturing/:manufacturingSolutions" : lang === "Jp" ? "/jp/manufacturing/:manufacturingSolutions" : "/manufacturing/:manufacturingSolutions" } element={ <ManufacturingSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/security" : lang === "Jp" ? "/jp/security" : "/security" } element={ <Security /> } />
                <Route path={ lang === "Zh" ? "/tw/security/:securitySolutions" : lang === "Jp" ? "/jp/security/:securitySolutions" : "/security/:securitySolutions" } element={ <SecuritySubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/about" : lang === "Jp" ? "/jp/about" : "/about" } element={ <About /> } />
                <Route path={ lang === "Zh" ? "/tw/about/team" : lang === "Jp" ? "/jp/about/team" : "/about/team" } element={ <TeamMembers /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies" : lang === "Jp" ? "/jp/about/technologies" : "/about/technologies" } element={ <Technologies /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/atom" : lang === "Jp" ? "/jp/about/technologies/atom" : "/about/technologies/atom" } element={ <Atom /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/atom/:atomTools" : lang === "Jp" ? "/jp/about/technologies/atom/:atomTools" : "/about/technologies/atom/:atomTools" } element={ <AtomTools /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/ardi" : lang === "Jp" ? "/jp/about/technologies/ardi" : "/about/technologies/ardi" } element={ <Ardi /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/ardi/:ardiTools" : lang === "Jp" ? "/jp/about/technologies/ardi/:ardiTools" : "/about/technologies/ardi/:ardiTools" } element={ <ArdiTools /> } />
                <Route path={ lang === "Zh" ? "/tw/about/technologies/:aboutTech" : lang === "Jp" ? "/jp/about/technologies/:aboutTech" : "/about/technologies/:aboutTech" } element={ <AboutTechSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/careers" : lang === "Jp" ? "/jp/careers" : "/careers" } element={ <Careers /> } />
                <Route path={ lang === "Zh" ? "/tw/careers/:openPositions" : lang === "Jp" ? "/jp/careers/:openPositions" : "/careers/:openPositions" } element={ <OpenPositions /> } />
                <Route path={ lang === "Zh" ? "/tw/contact-us" : lang === "Jp" ? "/jp/contact-us" : "/contact-us" } element={ <ContactUs /> } />

                <Route path={ lang === "Zh" ? "/tw/blog" : lang === "Jp" ? "/jp/blog" : "/blog" } element={ <Blog /> } />
                <Route path={ lang === "Zh" ? "/tw/blog/:blogPosts" : lang === "Jp" ? "/jp/blog/:blogPosts" : "/blog/:blogPosts" } element={ <BlogSubpages /> } />

                <Route path={ lang === "Zh" ? "/tw/newsroom" : lang === "Jp" ? "/jp/newsroom" : "/newsroom" } element={ <NewsRoom /> } />
                <Route path={ lang === "Zh" ? "/tw/newsroom/:newsStories" : lang === "Jp" ? "/jp/newsroom/:newsStories" : "/newsroom/:newsStories" } element={ <NewsSubpages /> } />

                <Route path="*" element={ <NoContent /> } />
            </Routes>
            { showJoinUs && <JoinUsSection /> }
            <Footer />
        </>
    );
};

