// import image
import roboticsLogo from "../../image/Logo_robotics.svg";
import bannerImg from "../../image/robotics/freepik__expand__8375.png";
import sectionImg from "../../image/digital-art-ai-technology.jpg";
import aiiFrame from "../../image/robotics/aiia_frame.png";
import aiiaVideo from "../../image/robotics/aiia_vid.mp4";
import RestaurantsAndCafés1 from "../../image/robotics/store_solution.jpg";
import ElectronicsAndAppliances1 from "../../image/robotics/store-with-many-items-shelves-including-microwave-toaster.jpg";
import BuildingMaterialsAndHardwares1 from "../../image/robotics/customer-hardware-store-does-select-hammer-tool-showcase.png";
import Furniture1 from "../../image/robotics/room-with-couch-table-with-plant-it.jpg";
import Supermarket1 from "../../image/robotics/elderly-woman-shopping-customer-day.jpg";
import HotelsAndConferences1 from "../../image/robotics/guest-hotel-is-ringing-bell-reception.jpg";
import Attractions1 from "../../image/robotics/black-podium-beneath-canopy-bioluminescent-trees.jpg";
import Transportation1 from "../../image/robotics/close-up-portrait-traveling-woman.jpg";

// import data
import contentData from "../../data/content/agents/agentsContent.json";
import { DataStructure } from "../../types";
// import doc
import aiiaBrochure from "../../docs/Graphen Brochure dec 9 2024.pdf";
// import components
import { NavTree } from "../navigation/NavLinkPages";
import { MainFrame, Col2ContentFrame, SpacingFrame, Col3ContentFrame,ColAutoFrame } from "../display/Frame";
import {StepsCard2} from "../display/Cards";
import { BusinessBannerSection, BannerSection, StatsSection } from "../display/Sections";
import { ImageImport, ImageBanner } from "../display/ImageFormat";
import { TextBody, TitleSubtitle, TextBulletList } from "../display/TextFormat";
import { PDFViewer } from "../tools/Viewer";
import { ArdiToolsOverview } from "../content/ToolsOverview";
import { MoreButton } from "../tools/Buttons";
import { AiiaHardwareModels, AiiaRolesSlideShow } from "../content/DigitalHuman";
import { SlideInAnim } from "../tools/Animations";
// import functions
import { DisplayInfo2Cols, DisplayProductCards } from "../../functions/DisplayInfoFunctions";
// import context
import { useLang } from "../Context";
// import global var
import { maxWidthScreen } from "../GlobalVar";

const typedContent : DataStructure = contentData;
const imagePath = "image/robotics/";

//////////////////////
// LOCAL FUNCTIONS //
/////////////////////
interface BannerProps {
    title: string;
    subtitle: string;
};
function DefaultBanner({ title, subtitle } : BannerProps) {
    return (
        <BannerSection image={bannerImg} title={ title } subtitle={ subtitle } />
    )
};


export default function Agents() {
    const { lang } = useLang();
    const data = typedContent["Agents"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];

    return (
        <>
            <BusinessBannerSection 
                bgImage={ bannerImg }
                logoImage={ roboticsLogo }
                title={ data["title"][lang] }
                subtitle={ data["subtitle"][lang] }
            />

            <NavTree />

            <DisplayInfo2Cols contentData={ section0["content"] } imagePath={imagePath} title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } />

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <Col2ContentFrame>
                        <DisplayProductCards data={ section1["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section2["title"][lang] } />
                    <Col2ContentFrame>
                        <DisplayProductCards data={ section2["solutions"] } />
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>

            {/* <ArdiToolsOverview isScroll /> */}
            {/* <ImageBanner image={sectionImg} /> */}

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section3["title"][lang] } />
                    <Col2ContentFrame>
                        <SlideInAnim direction="left">
                            <ImageImport image={ section3["content"]["image"] } imagePath="image/robotics/" />
                        </SlideInAnim>
                        <div className="grid grid-flow-row auto-rows-min gap-6 text-base">
                            <p className="text-lg text-left font-semibold">{ section3["content"]["subtitle"][lang] }</p>
                            <TextBody text={ section3["content"]["text"][lang] } />
                            <TextBulletList list={ section3["content"]["list"][lang] } />
                        </div>
                    </Col2ContentFrame>
                </SpacingFrame>
            </MainFrame>
        </>
    )
};

export function AiiaBrochurePDF() {
    return (
        <PDFViewer src={aiiaBrochure} />
    )
};


export function Aiia() {
    const { lang } = useLang();
    const data = typedContent["digital-human"];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];

    let aiiaFeatures = Object.keys(section4["content"]).map((i) => {
        let data = section4["content"][i];
        let image = require("../../image/icons/" + data["icon"]);
        return (
            <div className="w-full h-fit flex flex-col items-center justify-center gap-8">
                <div className="relative w-20 h-20 rounded-full bg-teal-500">
                    <div className="absolute w-20 h-20 flex items-center justify-center">
                        <img className="w-10 h-10" src={ image } />
                    </div>
                </div>
                <SpacingFrame spacing={12}>
                    <p className="text-md font-semibold">{ data["title"][lang] }</p>
                    <p className="text-sm text-grey-400">{ data["subtitle"][lang] }</p>
                </SpacingFrame>
            </div>
        )
    });


    return (
        <>
            <div className="relative w-full h-screen bg-cover bg-center bg-grey-800 bg-blend-color-burn overflow-hidden">
                <div className="absolute w-[782px] h-[782px] rounded-full blur-3xl filter opacity-60 top-[15%] left-[43%]" style={{ backgroundColor: "#57CBB6" }}/>
                <div className="absolute w-[616px] h-[616px] rounded-full blur-3xl filter opacity-60 top-[64%] left-[11%]" style={{ backgroundColor: "#5B68DF" }}/>

                <div className="absolute flex w-full m-auto lg:mt-0 sm:mt-20">
                    <div className="lg:grid lg:grid-cols-5 sm:flex sm:flex-col gap-8 w-full h-screen m-auto lg:p-0 sm:p-10" style={{ maxWidth: `${maxWidthScreen}px` }}>
                        <div className="col-span-2 w-fit flex flex-col gap-4 m-auto break-words">
                            <p className="text-left text-2xl text-white font-bold lg:leading-tight sm:leading-none break-words">{ data["title"][lang] }</p>
                            <p className="text-left text-white font-light leading-relaxed break-words">{ data["subtitle"][lang] }</p>
                        </div>
                        <div className="relative col-span-3 mx-auto">
                            <img className="absolute lg:-bottom-8 sm:-bottom-12 lg:min-w-[400px] sm:w-fit object-cover bg-center flex-shrink-0" src={aiiFrame} />
                            <video className="lg:w-[400px] lg:max-w-fit lg:h-[750px] sm:max-w-[320px] sm:w-fit flex-shrink-0 m-auto lg:mt-[20vh]" autoPlay muted loop controls src={aiiaVideo} />
                        </div>
                    </div>
                </div>
            </div>

            <NavTree />

            <MainFrame>
                <Col2ContentFrame>
                    <SpacingFrame spacing={20}>
                        <TitleSubtitle title={ section1["title"][lang] } subtitle={ section1["subtitle"][lang] } left />
                        <TextBody text={ section1["text"][lang] } />
                        <MoreButton label="Download Aiia PDF" link="https://www.drugomics.graphen.ai/doc/brochure.pdf" newTab={true} />
                        {/* <MoreButton label="Download Aiia PDF" link="/robotics/aiia-brochure" /> */}
                    </SpacingFrame>
                    <SlideInAnim direction="right">
                        <ImageImport image={ section1["image"] } imagePath={imagePath} />
                    </SlideInAnim>
                </Col2ContentFrame>
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(162, 218, 219, 0.4)" }}>
                <StatsSection title={ section2["title"][lang] } subtitle={ section2["subtitle"][lang] } data={ section2["content"] } />
            </MainFrame>

            <div className="w-full h-fit overflow-hidden">
                <div className="m-auto" style={{ maxWidth: `${maxWidthScreen}px` }}>
                    <div className="w-full h-fit pt-32 sm:px-8">
                        <SpacingFrame spacing={20}>
                            <TitleSubtitle title={ section3["title"][lang] } subtitle={ section3["subtitle"][lang] } />
                            <SlideInAnim direction="bottom">
                                <ImageImport image={ section3["image"] } imagePath={imagePath} />
                            </SlideInAnim>
                        </SpacingFrame>
                    </div>
                </div>
            </div>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)", overflow: "hidden" }}>
                <SpacingFrame>
                    <TitleSubtitle title={ section4["title"][lang] } />
                    <SlideInAnim direction="bottom">
                        <Col3ContentFrame>
                            { aiiaFeatures }
                        </Col3ContentFrame>
                    </SlideInAnim>
                </SpacingFrame>
            </MainFrame>

            <MainFrame>
                <SpacingFrame>
                    <TitleSubtitle title={ section5["title"][lang] } />
                    <AiiaRolesSlideShow />
                </SpacingFrame>
            </MainFrame>

            <AiiaHardwareModels />

            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath} title={ section6["title"][lang] } />
        </>
    )
};


export function StoreSolutions() {
    const { lang } = useLang();
    const data = typedContent["store-solutions"];
    const contentData = data["content"];

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            
            <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} />
        </>
    )
};


export function RestaurantsAndCafés() {
    const { lang } = useLang();
    const data = typedContent["RestaurantsAndCafés"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section3 = data[3];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];

    let devSteps = Object.keys(section1["content"]).map((i) => {
            let content = section1["content"][i];
            return (
                <StepsCard2 data={content} />
            )
        });

    return (
        <>
            <BannerSection image={RestaurantsAndCafés1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            


            {/* <BannerSection image={pipelineBanner} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } textPosition="Top" /> */}
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <div style={{ backgroundColor: "rgba(248, 249, 250, 1)"}}>
            <DisplayInfo2Cols contentData={section3["content"]} imagePath={imagePath} title={ section3["title"][lang] }  />
            </div>
            <DisplayInfo2Cols contentData={section4["content"]} imagePath={imagePath} title={ section4["title"][lang] }  />
            <div style={{ backgroundColor: "rgba(248, 249, 250, 1)"}}>
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            </div>
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};


export function ElectronicsAndAppliances() {
    const { lang } = useLang();
    const data = typedContent["ElectronicsAndAppliances"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];


    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });

    return (
        <>
            <BannerSection image={ElectronicsAndAppliances1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section4["content"]} imagePath={imagePath}  />
            <div style={{ backgroundColor: "rgba(248, 249, 250, 1)"}}>
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            </div>
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};


export function BuildingMaterialsAndHardwares() {
    const { lang } = useLang();
    const data = typedContent["BuildingMaterialsAndHardwares"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section6 = data[6];

    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });


    return (
        <>
            <BannerSection image={BuildingMaterialsAndHardwares1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};


export function Furniture() {
    const { lang } = useLang();
    const data = typedContent["Furniture"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section5 = data[5];
    const section6 = data[6];


    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });

    return (
        <>
            <BannerSection image={Furniture1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] }/>
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <div style={{ backgroundColor: "rgba(248, 249, 250, 1)"}}>
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            </div>
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};


export function Supermarket() {
    const { lang } = useLang();
    const data = typedContent["Supermarket"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section5 = data[5];
    const section6 = data[6];


    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });

    return (
        <>
            <BannerSection image={Supermarket1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};

export function HotelsAndConferences() {
    const { lang } = useLang();
    const data = typedContent["HotelsAndConferences"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];

    let devSteps = Object.keys(section1["content"]).map((i) => {
            let content = section1["content"][i];
            return (
                <StepsCard2 data={content} />
            )
        });

    return (
        <>
            <BannerSection image={HotelsAndConferences1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section4["content"]} imagePath={imagePath}  />
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};

export function Attractions() {
    const { lang } = useLang();
    const data = typedContent["Attractions"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section4 = data[4];
    const section5 = data[5];
    const section6 = data[6];


    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });


    return (
        <>
            <BannerSection image={Attractions1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section4["content"]} imagePath={imagePath}  />
            <DisplayInfo2Cols contentData={section5["content"]} imagePath={imagePath}  />
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};

export function Transportation() {
    const { lang } = useLang();
    const data = typedContent["Transportation"];
    const section0 = data[0];
    const section1 = data[1];
    const section2 = data[2];
    const section6 = data[6];


    let devSteps = Object.keys(section1["content"]).map((i) => {
        let content = section1["content"][i];
        return (
            <StepsCard2 data={content} />
        )
    });

    return (
        <>
            <BannerSection image={Transportation1} title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />
            <MainFrame>
            <TitleSubtitle title={ section0["title"][lang] } subtitle={ section0["subtitle"][lang] } spacing={30} />
            </MainFrame>

            <MainFrame style={{ backgroundColor: "rgba(248, 249, 250, 1)" }}>
                <SpacingFrame spacing={60}>
                    <TitleSubtitle title={ section1["title"][lang] } />
                    <ColAutoFrame>
                        { devSteps }
                    </ColAutoFrame>
                </SpacingFrame>
            </MainFrame>
            
            
            <DisplayInfo2Cols contentData={section2["content"]} imagePath={imagePath} title={ section2["title"][lang] }  />
            <DisplayInfo2Cols contentData={section6["content"]} imagePath={imagePath}  />
        </>
    )
};


export function Adam() {
    const { lang } = useLang();
    const data = typedContent["adam"];
    const contentData = data["content"];

    return (
        <>
            <DefaultBanner title={ data["title"][lang] } subtitle={ data["subtitle"][lang] } />
            <NavTree />

            <DisplayInfo2Cols contentData={contentData} imagePath={imagePath} />
        </>
    )
};